
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import Slider from './slider';

$(() => {
   const sliders = document.querySelectorAll('.field--12 .slider');

   if (sliders.length > 0) {
       sliders.forEach((slider) => {
           const object = new Slider(slider);
           object.init();
       });
   }
});

$('.hamburger').click(function () {
   $(this).toggleClass('active');
   $('.mobile').toggleClass('active');
});

$('.logo').click(function () {
    window.location = '/';
});

$('.list .list .active').parents('.list').parents('.-has-submenu').addClass('active');

min_height();
window.addEventListener("resize", min_height);

function min_height() {
    let footer_height = $('footer').css('height');
    $('main').css('min-height', 'calc(100vh - ' + footer_height + ')');

    let item_width = $('.news .item').css('width');
    $('.item .image').css('height', 'calc('+ item_width +' * 0.5)');
}

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app'
// });