export default class Slider {

    /**
     * @param   {Element} element
     */
    constructor(element) {
        /**
         * @type {Element}
         * @private
         */
        this._container = element;

        /**
         * @type {NodeListOf<Element>}
         */
        this.slides = this._container.querySelectorAll('.slide');

        /**
         * @type {number}
         */
        this.index = 0;

        /**
         * @type {Element | any}
         * @private
         */
        this._controls = this._container.querySelector('.controls');

        /**
         * @type {Element | any}
         * @private
         */
        this._next = this._container.querySelector('.next');

        /**
         * @type {Element | any}
         * @private
         */
        this._previous = this._container.querySelector('.previous');
    }

    init() {
        $(this.slides).hide();
        $(this.slides[this.index]).fadeIn();

        if (this.slides.length > 1) {
            this._controls.style.display = 'flex';
        } else {
            this._controls.style.display = 'none';
        }

        this._next.addEventListener('click', () => {
            this.next();
        });
        this._previous.addEventListener('click', () => {
            this.previous();
        });
    }

    /**
     * @returns {boolean}
     */
    hasNext() {
        const nextIndex = this.index + 1;
        return this.slides[nextIndex] !== undefined;
    }

    hasPrevious() {
        const previousIndex = this.index - 1;
        return previousIndex >= 0;
    }

    next() {
        if (this.hasNext()) {
            $(this.slides[this.index]).fadeOut();

            this.index++;

            $(this.slides[this.index]).fadeIn();
        } else {
            $(this.slides[this.index]).fadeOut();

            this.index = 0;

            $(this.slides[this.index]).fadeIn();
        }
    }

    previous() {
        if (this.hasPrevious()) {
            $(this.slides[this.index]).fadeOut();

            this.index--;

            $(this.slides[this.index]).fadeIn();
        } else {
            $(this.slides[this.index]).fadeOut();

            this.index = (this.slides.length - 1);

            $(this.slides[this.index]).fadeIn();
        }
    }
}